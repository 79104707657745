.profilePage {
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    /* margin: 32px 64px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow: hidden; */
    padding: 0 16px;
    /* border: 1px solid var(--palette-2-4); */
    /* border-radius: 12px; */
    border-top: 0;
    /* background: var(--palette-2-7); */
    border-bottom: 0;
}

.profileCover {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 64px;
    position: relative;
    /* background: linear-gradient(180deg, #0065b338, transparent); */
}

.profileCoverBottom {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 96px;
    position: relative;
    margin-top: auto;
    background: linear-gradient(180deg, transparent, #0065b338);
}

    .profilePhotoContainer {
        position: absolute;
        display: flex;
        align-items: center;
        margin-top: 16px;
    }
    


        .profilePhotoContainer .buttonContainer {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: opacity 0.15s;
            opacity: 0.5;
            box-shadow: 0px 0px 8px;
            z-index: 1;
            /* border: 1px solid; */
            /* border: solid 1px var(--palette-2-4); */
        }
            .profilePhotoContainer .buttonContainer svg {
                width: 20px;
                height: 20px;
            }

        .profilePhotoContainer .buttonContainer:hover {
            cursor: pointer;
            opacity: 1;
        }

        .profilePhotoContainer .leftBtn {
            right: 8px;
            position: relative;
            border-radius: 4px;
        }
        .profilePhotoContainer .rightBtn {
            position: relative;
            border-radius: 4px;
            right: -8px;
        }
        .profilePhotoContainer .uploadBtn {
            color: var(--palette-1-4);
            /* background: linear-gradient(0deg, var(--palette-1-6), transparent); */
        }

        .profilePhotoContainer .minusBtn {
            color: var(--palette-6-4);
            /* background: linear-gradient(0deg, var(--palette-6-7), transparent); */
        }

        .profilePhotoContainer .minusBtnInactive {
            pointer-events: none;
        }

        .profilePhotoContainer .okBtn {
            color: var(--palette-4-4);
        }

        .profilePhotoContainer .crossBtn {
            color: var(--palette-2-2);
        }

    .profilePhotoContainer .profilePhoto {
        width: 128px;
        height: 128px;
        border-radius: 50%;
        padding: 3px;
        background: linear-gradient(0deg, transparent, white, transparent);
        border: solid 2px var(--palette-2-4);
        z-index: 1002;
        box-shadow: 0px 0px 8px white;
    }

    .profilePhotoContainer .noPhoto {
        margin: 0!important;
        opacity: 1!important;
        width: 128px!important;
        height: 128px!important;
        background: white!important;
        pointer-events: none!important;
        border-radius: 50%;
        padding: 3px;
        border: solid 3px var(--palette-2-2);
        color: var(--palette-2-5);
    }

.infoEmailBlock {
    width: 100%;
    position: relative;
    animation: margin-fadein 1.2s ease-out;
    /* border-bottom: solid 2px var(--palette-2-2); */
}
@keyframes margin-fadein {
    from {
        opacity: 0;
        top: -16px;
    }
    to {
        opacity: 1;
        top: 0;
    }            
}
    .emailBlock {
        height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

        .emailBlock .role {
            height: fit-content;
            color: var(--palette-1-5);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
        }
            .emailBlock .role svg {
                width: 26px;
                height: 26px;
            }

        .emailBlock .email { 
            color: var(--palette-1-2);
            display: flex;
            align-items: center;
            height: 100%;
            border-radius: 8px;
            box-shadow: 1px 0px 13px 0px var(--palette-2-4);
            overflow: hidden;
            /* background: var(--palette-2-5); */
            border: 2px solid var(--palette-2-6);
            background: linear-gradient(180deg, #e7e7e7, transparent);
            font-weight: 600;
        }

            .emailBlock .email .emailIconContainer {
                display: flex;
                justify-content: center;
                height: 32px;
                width: 37px;
                align-items: center;
                color: var(--palette-1-7);
                border-right: 2px solid var(--palette-2-4);
                box-shadow: 0px 16px 12px 0px var(--palette-1-5) inset;
                background: var(--palette-1-3);
                opacity: 0.75;
                z-index: 101;
            }


                .emailBlock .email .emailIconContainer:hover {
                    opacity: 1;
                    cursor: pointer;
                }
                .emailBlock .emailIconContainer svg { 
                    width: 26px;
                    height: 26px;
                }

            .emailBlock .email text {
                font-size: 18px;
                font-family: monospace;
                margin: 0 8px;
                color: var(--palette-2-3);
                pointer-events: none;
            }

    .infoBlock {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        box-shadow: 0px 0px 8px var(--palette-2-5);
        margin-top: 64px;
        border-radius: 16px;
        border: 1px solid var(--palette-2-6);
        /* width: border-left: solid 3px var(--palette-2-2); */
        opacity: 0;
        width: 0%;
        animation: block-fadein 0.45s ease-in-out 0.45s forwards;
    }
    
    @keyframes block-fadein {
        from {
            width: 0%;
            opacity: 1;
        }

        to {
            width: 100%;
            opacity: 1;
        }
    }

        .infoBlock .tableHeader { 
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: linear-gradient(180deg, #e7e7e7, transparent);
            height: 36px;
            font-size: 22px;
            font-weight: 500;
            border-bottom: 1px solid var(--palette-2-5);
            pointer-events: none;
            /* background: #e7e7e7; */
        }

            .infoBlock .tableHeader .title,
            .coursesContainer .tableHeader .title {
                display: flex;
                height: 100%;
                align-items: center;
                color: var(--palette-2-3);
            }

                .infoBlock .tableHeader .title .titleImg,
                .coursesContainer .tableHeader .title .titleImg {
                    height: 100%;
                    width: 37px;
                    border-right: solid 1px var(--palette-2-5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                    .infoBlock .tableHeader .title .titleImg svg,
                    .coursesContainer .tableHeader .title .titleImg svg {
                        width: 26px;
                        height: 26px;
                    }

                .infoBlock .tableHeader .title text,
                .coursesContainer .tableHeader .title text { 
                    font-weight: 700;
                    font-size: 18px;
                    margin-left: 6px;
                }
        
        .infoBlock .infoBody {
            display: flex;
            width: 100%;
            /* width: border-left: solid 3px var(--palette-2-2); */
        }
            .infoBody .profileInfoContainer {
                /* padding: 8px 16px; */
                padding-left: 0px;
                /* width: calc(50% - 1px); */
                width: 100%;
                display: flex;
                /* flex-direction: column; */
                /* align-items: stretch; */
            }
                
            /* .infoBody .profileInfoContainer td {
                padding: 4px;
                margin: 4px 0;
                width: 50%;
                width: 50%;
            } */
            .infoBody .profileInfoContainer .lotion {
                height: 100%;
                min-width: 37px;
                box-shadow: 0px 3px 8px -1px var(--palette-2-2);
                border-right: 1px solid var(--palette-2-5);
                background: linear-gradient(0deg, var(--palette-2-5), transparent);
                opacity: 0.5;
            }
            .infoBody .profileInfoContainer .infoContainer {
                display: flex;
                padding: 16px;
                padding-left: 4px;
                padding-bottom: 4px;
                width: 100%;
                flex-wrap: wrap;
            }

                .infoBody .profileInfoContainer .infoContainer .infoItem {
                    display: flex;
                    flex: 1 1;
                    border: 1px solid var(--palette-2-6);
                    background: linear-gradient(0deg, #00000014, transparent);
                    border-radius: 4px;
                    margin-bottom: 12px;
                    margin-left: 12px;
                    height: 32px;
                    overflow: hidden;
                    
                }
                    .infoBody .profileInfoContainer .infoContainer .infoItem.editing {
                        display: flex;
                        justify-content: space-between;
                    }

                    .infoBody .profileInfoContainer .infoContainer .infoItem.editing input {
                        width: 100%;
                        text-align: center;
                    }
                    .infoBody .profileInfoContainer .infoContainer .infoItem.editing .okBtn,
                    .infoBody .profileInfoContainer .infoContainer .infoItem.editing .cancelBtn {
                        min-width: 32px;
                        min-height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: var(--palette-2-2);
                        box-shadow: 0px 0px 8px -1px inset;
                        opacity: 0.5;
                        transition: all 0.15s;
                    }
                    .infoBody .profileInfoContainer .infoContainer .infoItem.editing .okBtn:hover,
                    .infoBody .profileInfoContainer .infoContainer .infoItem.editing .cancelBtn:hover {
                        cursor: pointer;
                        opacity: 1;
                    }
                    .infoBody .profileInfoContainer .infoContainer .infoItem.editing .okBtn {
                        color: var(--palette-4-4);
                    }
                    .infoBody .profileInfoContainer .infoContainer .infoItem.editing svg {
                        width: 20px;
                        height: 20px;
                    }
            
            .infoBody .profileInfoContainer .key {
                height: 100%;
                min-width: 32px;
                font-weight: 900;
                color: var(--palette-2-3);
                text-align: end;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: math;
                font-size: 20px;
                border-right: 1px solid var(--palette-2-6);
                pointer-events: none;
            }
                .infoBody .profileInfoContainer .key svg {
                    width: 16px;
                    height: 16px;
                }
            .infoBody .profileInfoContainer .value {
                height: 100%;
                width: 100%;
                font-size: 16px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: monospace;
                overflow: hidden;
            }
                .infoBody .profileInfoContainer .value span {
                    overflow: hidden; 
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin: 0 8px;
                }
            
            .infoBody .profileInfoContainer .edit {
                height: 100%;
                min-width: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                /* border-left: solid 1px var(--palette-2-4); */
                opacity: 0.5;
            }
                .infoBlock .profileInfoContainer .edit:hover {
                    cursor: pointer;
                    opacity: 1;
                    background: linear-gradient(0deg, #aad4f580, transparent);
                } 
                .infoBlock .profileInfoContainer .edit svg { 
                    color: var(--palette-1-4);
                    width: 16px;
                    height: 16px;
                }
    
    .coursesBlock {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin: 16px 0;
        border-radius: 16px;
        box-shadow: 0px 0px 8px var(--palette-2-5);
        opacity: 0;
        width: 0%;
        animation: block-fadein 0.45s ease-in-out 0.75s forwards;
    }
        
    .coursesContainer {
        display: flex;
        flex-direction: column;
    }
    .coursesContainer .coursesScroll {
        flex: 1;
        display: flex;
        overflow: hidden;
        position: relative;
    }
        .coursesContainer .tableHeader {
            height: 36px;
            background: linear-gradient(180deg, #e7e7e7, white);
            border-bottom: 1px solid var(--palette-2-5);
            /* border-top: 1px solid var(--palette-2-5); */
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 18px;
            color: #242424;
            pointer-events: none;
        }
        
        .coursesContainer .coursesScroll .coursesScrollViewContainer {
            width: 100%;
            flex: 1 1;
            display: flex;
            justify-content: end;
            overflow: hidden;
            position: relative;
        }
        
            .coursesContainer .coursesScroll .coursesScrollViewContainer .coursesScrollView {
                display: flex;
                /* right: 0px; */
                min-height: 216px;
                /* min-width: 100%; */
                position: relative;
                transition: right 0.5s ease-in-out;
            }
        
                .coursesContainer .coursesScroll .coursesScrollViewContainer .coursesScrollView .noCoursesContainer{  
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    color: var(--palette-2-4);
                }

            .coursesContainer .scrollButtonLeft,
            .coursesContainer .scrollButtonRight {
                width: 36px;
                height: 100%;
                z-index: 100;
                background: linear-gradient(0deg, var(--palette-2-5), #ffffff, var(--palette-2-5));
                /* border-style: outset; */
                border-top: 0;
                opacity: 0.5;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 0 8px var(--palette-2-2);
            }
            .coursesContainer .scrollButtonLeft {
                border-right: 0;
            }
            .coursesContainer .scrollButtonRight {
                border-left: 0;
                right: 0;
            }

                .coursesContainer .scrollButtonRight svg,
                .coursesContainer .scrollButtonLeft svg {
                    width: 26px;
                    height: 26px;
                    color: var(--palette-2-4);
                }

                .coursesContainer .scrollButtonLeft.active svg,
                .coursesContainer .scrollButtonRight.active svg {
                    color: var(--palette-1-4);
                }

                .coursesContainer .scrollButtonLeft.active:hover,
                .coursesContainer .scrollButtonRight.active:hover {
                    cursor: pointer;
                    opacity: 0.75;
                }
        
            /* .coursesBlock .coursesTableScrollable::after {
                content: "";
                flex: auto;
            } */

@media (max-width: 720px) {
    .infoBody .profileInfoContainer .infoContainer .infoItem {
        flex-basis: calc(50% - 12px);
    }
}

@media (max-width: 480px) {
    .infoBody .profileInfoContainer .infoContainer .infoItem {
        flex-basis: calc(100% - 12px);
    }

    .profilePhotoContainer .profilePhoto {
        width: 96px;
        height: 96px;
        /* order: 1; */
    }

    .profilePhotoContainer .leftBtn,
    .profilePhotoContainer .rightBtn {
        position: static;
        margin: 4px;
        width: 28px;
        height: 28px;
    }

    .profilePhotoContainer .leftBtn svg,
    .profilePhotoContainer .rightBtn svg{
        position: static;
        margin: 4px;
        width: 18px;
        height: 18px;
    }

    .profileCover {
        /* justify-content: end; */
        min-height: 128px;
    }

    .emailBlock .email text {
        font-size: 14px;
    }
    
    .infoBlock {
        margin-top: 20px;
    }
    
    .emailBlock .role svg {
        width: 22px;
        height: 22px;
    }

    .emailBlock .email { 
        order: 1;
    }

    .profilePhotoContainer .profilePhoto {
        width: 96px;
        height: 96px;
    }

    .profilePhotoContainer .noPhoto {
        width: 96px!important;
        height: 96px!important;
    }
}