.list {
    width: 100%;
}
    .list .listHeader {
        display: flex;
        justify-content: start;
        align-items: center;
        border-top: solid 1px #0000000d;
        margin: 0 24px;
        padding: 8px 0;
    }
        .listHeader .title {
            display: flex;
            justify-content: start;
            align-items: center;
            color: var(--palette-2-2);
        }
        .listHeader .tools {
            display: flex;
            align-items: center;
            color: var(--palette-1-5);
            margin-left: 8px;
        }
        .listHeader .adder {
            opacity: 0.5;
        }
        
            .listHeader .adder:hover {
                opacity: 1;
                cursor: pointer;
            }

    .list .listBody {
        padding: 24px;
        padding-top: 0;
    }


    .list .defaultSectionItem {
        display: flex;
        align-items: center;
        /* background:linear-gradient(0deg, transparent, #00467d85); */
        height: 80px;
        padding-left: 80px;
        border-top: solid 1px var(--palette-2-6);
        background: #ffffff42;
        background: linear-gradient(270deg, transparent, #00467d12);
    }
    .defaultSectionItem h4 {
        margin: 0 8px;
        color: var(--palette-1-3);
    }
    .list .sectionItem {
        display: flex;
        align-items: center;
        /* background:linear-gradient(0deg, transparent, #00467d85); */
        padding: 8px;
        border-top: solid 1px var(--palette-2-6);
        background: #ffffff42;
    }

    .list .sectionImageContainer {

        height: 64px;
        width: 64px;
        display: flex;
        margin: 0px 8px;
    }

    .list .sectionNoPhoto {
        height: 100%;
        width: 100%;
        color: var(--palette-2-3);
        opacity: 0.5;
    }

        .sectionImageContainer img {
            display: block;
            margin: auto;
            margin-top: auto;
            text-align: center;
        }

        .sectionImageContainer .imgHorisontal {
            width: 100%;
            height: auto;
        }

        .sectionImageContainer .imgVertical {
            height: 100%;
            width: auto;
        }

    .list .sectionItemInput {
        
        border: none;
        outline: none;
        background: none;
        border-bottom: 1px solid #2368a247;
    }

    .list .sectionTextContainer {
        display: flex;
        flex-direction: column;
        height: 48px;
        max-width: 70%;
    }
        .sectionTextContainer textarea {
            margin-top: 8px
        }


    .list .sectionItemName {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        font-weight: 600;
        color: var(--palette-2-2);
    }

    .list .sectionItemDescription {
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }

    .sectionItem .imageUploader,
    .sectionItem .imageMinus { 
        margin:2px;
        color: var(--palette-1-5);
        opacity: 0.5;
    }
        .sectionItem .imageUploader:hover,
        .sectionItem .imageMinus:hover { 
            cursor: pointer;
            opacity: 1;
        }

    .sectionItem .imageMinus {
        color: var(--palette-6-4)
    }   
    
    .sectionItem .insertSectionSubmit {
        color: var(--palette-4-4)!important;
        opacity: 0.5;
        right: 0;
        margin-left: auto;
    }
        .sectionItem .insertSectionSubmit:hover {
            cursor: pointer;
            opacity: 1;
        }

    .sectionItem .buttons {
        margin-left: auto;
    }
        .sectionItem .buttons>* {
            margin: 2px;
            color: var(--palette-1-5);
            opacity: 0.5
        }
        .sectionItem .buttons>*:hover {
            cursor: pointer;
            opacity: 1
        }
        .sectionItem .buttons .trash {
            color: var(--palette-6-5)
        }

        .popupBackground {
            position: fixed;
            z-index: 1001;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.6);
            display: flex;
            align-items: center;
            justify-content: center;
        }