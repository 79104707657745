.toasterWrapper > div > div {
    background: transparent;
}

.toasterWrapper > div {
    font-size: 16px;
    display: none !important; 
}

.toasterWrapper > div:nth-child(-n+3) { 
    display: flex !important; 
}