.sorterWrapper {
    display: flex;
    align-items: center;
}
        
.sorterWrapper::after {
    content: "";
    border-right: 1px solid var(--palette-2-6);
    margin-left: 8px;
    height: calc(100% + 12px);
}

.sorter {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: auto;
    position: relative;
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
}

    .sorter .text {
        display: flex;
        align-items: center;
        opacity: 0.5;
        color: var(--palette-1-1);
    }
    .sorter:hover .text {
        cursor: pointer;
        opacity: 1;
    }
    
    .sorter:hover .dropdown {
        display: flex;
        align-items: center;
        flex-direction: column;
        opacity: 1;
        animation: dropdown-fadein 0.2s;
    }
    
        @keyframes dropdown-fadein {
            from { opacity: 0; }
            to { opacity: 1; }
        }
    

        .sorter .text svg {
            color: var(--palette-4-4);
        }

    .sorter .dropdown {
        display: none;
        opacity: 0;
        position: absolute;
        top:14px;
        background: rgb(255 255 255);
        min-width: 96px;
        z-index: 100;
        padding: 4px 8px;
        border-radius: 0 0 4px 4px;
        margin-left: -8px;
    }
        .sorter .dropdown span {
            margin: 4px 0px;
            opacity: 0.5;
            text-decoration: underline;
        }
        .sorter .dropdown span:hover{
            opacity: 1;
            cursor: pointer;
        }