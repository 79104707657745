.newLessonPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

    .newLessonPage .nameEditor {
        margin: 24px;
        padding: 24px;
    }

    .newLessonPage .contentEditor {
        margin: 24px;
        padding: 24px;
    }

    .newLessonPage .createButton:hover {
        cursor: pointer;
    }
