.unauthorizedContent {
    display: flex;    
    position: relative;
    margin-top: var(--header-height);
    overflow: auto;
    /* background: white; */
    /* height: fit-content; */
    width: 100%;
    /* max-height: calc(100vh - 1px); */
    /* height: calc(100vh - 1px - var(--header-height)); */
    /* min-height: 640px; */
    /* box-shadow: 0px 0px 20px -10px black; */
    
    /* margin-top: 64px; */
}