.adminSectionsContainer {
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    overflow-y: hidden;
    /* max-height: calc(100vh - 1px); */
    transition: max-height 0.3s ease-in-out;
    padding: 0 48px;
}

.collapsed {
    /* max-height: 0!important; */
}
    .adminSectionsList {
        opacity: 1;
        /* transition: opacity 0.4s ease-in-out; */
    }

    .collapsed .adminSectionsList {
        /* opacity: 0!important; */
    }

    .adminSectionsContainer .header {
        display: flex;
        align-items: center;
        width: 100%;
        width: -moz-available;          /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
        width: fill-available;
        /* border: solid 1px var(--palette-2-6); */
        min-height: 36px;
        animation: header-fadein 0.3s ease-out forwards;
        opacity: 0;
    }

    @keyframes header-fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .adminSectionsContainer .collapser {
        height: 100%;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--palette-1-5);
        border: 1px solid var(--palette-2-6); 
        background: radial-gradient(transparent, #0000000f);
    }

    .adminSectionsContainer .collapser:hover {
        cursor: pointer;
        background: var(--palette-1-7);
    }

        .adminSectionsContainer .collapser svg {
            pointer-events: auto;
        }

        .adminSectionsContainer .collapser svg:hover {
            
        }

    .adminSectionsContainer .header .headerTitle {
        display: flex;
        align-items: center;
        margin-left: 12px;
        color: var(--palette-1-2);
        width: auto;
    }
    
        .adminSectionsContainer .header .clickIcons {
            padding: 0 12px;
            display: flex;
        }

        .adminSectionsContainer .header .clickIcons >* {
            width: 20px;
            height: 20px;
            margin: 4px 2px;
            color: var(--palette-2-4);
            opacity: 50%;
        }
        
        .adminSectionsContainer .header .clickIcons >*:hover, 
        .adminSectionsContainer .header .clickIcons >*.active {
            color: var(--palette-1-6);
            opacity: 1;
            cursor: pointer;
        }

        .adminSectionsContainer .header .sorter {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-left: auto;
            position: relative;
            margin-right: 8px;
            font-size: 12px;
            font-weight: 400;
            text-decoration: underline;
        }
            .adminSectionsContainer .header .sorter .text {
                display: flex;
                align-items: center;
                opacity: 0.5;
                color: var(--palette-1-1);
            }
            .adminSectionsContainer .header .sorter:hover .text {
                cursor: pointer;
                opacity: 1;
            }
            
            .adminSectionsContainer .header .sorter:hover .dropdown {
                display: flex;
                align-items: center;
                flex-direction: column;
                opacity: 1;
                animation: dropdown-fadein 0.2s;
            }
            
                @keyframes dropdown-fadein {
                    from { opacity: 0; }
                    to { opacity: 1; }
                }
            

                .adminSectionsContainer .header .sorter .text svg {
                    color: var(--palette-4-4);
                }

                .adminSectionsContainer .header .sorter .dropdown {
                display: none;
                opacity: 0;
                position: absolute;
                top:14px;
                background: rgb(255 255 255);
                min-width: 96px;
                z-index: 100;
                padding: 4px 8px;
                border-radius: 0 0 4px 4px;
                margin-left: -8px;
            }
            .adminSectionsContainer .header .sorter .dropdown span {
                margin: 4px 0px;
                opacity: 0.5;
                text-decoration: underline;
            }
            .adminSectionsContainer .header .sorter .dropdown span:hover{
                opacity: 1;
                cursor: pointer;
            }

.adminSectionsList {
    overflow-y: scroll;
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    margin: 24px 0;
    padding: 0 24px;
    border: 1px solid #8186bd14;
}

    .adminSectionsList .showMore {    
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
        font-size: 12px;
        border-radius: 8px;
        box-shadow: 0 0 6px -3px var(--palette-1-2);
    }
        .adminSectionsList .showMore:hover {
            background: radial-gradient(#ffffff, #d1e4ff5c);
            cursor: pointer;
        }
        .adminSectionsList .showMore svg {    
            height: 100%;
            width: 24px;
            color: #6495edc7;
        }

    .adminSectionsList .defaultItem {  
        margin-top: 16px;
        /* background: linear-gradient(0deg, var(--palette-2-7), #eff8ff)!important; */
    }
    
            .adminSectionsList .defaultItem .textContainer .name {
                color: var(--palette-1-3)!important;
            }
    
            .adminSectionsList .defaultItem .textContainer .description {
                font-weight: 500;
                color: var(--palette-1-5)!important;
            }

    .adminSectionsList .item {
        margin-bottom: 16px;
        background: linear-gradient(0deg, var(--palette-2-7), #ffffff);
        animation: sections-list-fadein 0.6s;
        display: flex;
        flex-wrap: wrap;    
        align-items: stretch;
        border: solid 1px #ededed;
    }
    .adminSectionsList .item .sectionChangeOrderButtons {
        display: flex;
        flex-direction: column;
        border-right: 1px solid var(--palette-2-6);
    }
    .adminSectionsList .item .sectionChangeOrderButtons>*:first-child {
        border-bottom: 1px solid var(--palette-2-6);
    }
        .adminSectionsList .item .sectionChangeOrderButtons .buttonWrapper {
            height: 50%;
            width: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--palette-1-5);
            opacity: 0.5;
                    
        }
            .adminSectionsList .item .sectionChangeOrderButtons .buttonWrapper:hover {
                cursor: pointer;
                opacity: 1;
                background: var(--palette-2-5);
            }
    
        .adminSectionsList .item .textContainer {
            flex: 1;
            display: flex;
            flex-direction: column;
            margin-left: 12px;
        }
    
            .adminSectionsList .item .textContainer text {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
            .adminSectionsList .item .textContainer .description text {
                -webkit-line-clamp: 2;
            }

            .adminSectionsList .item .textContainer .name {
                font-weight: 700;
                color: #462a91;
                height: 50%;
                display: flex;
                align-items: center;
            }

            .adminSectionsList .item .textContainer .description {
                font-size: 12px;
                height: 50%;
                font-weight: 400;
                color: var(--palette-1-2);
            }

    @keyframes sections-list-fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .item .imageContainer {
        width: 72px;
        height: 72px;
        display: flex;
        margin-left: 8px;
    }

        .item .imageContainer img {
            display: block;
            margin: auto;
            margin-top: auto;
            text-align: center;
        }

        .item .imageContainer .imgHorisontal {
            width: 100%;
            height: auto;
        }

        .item .imageContainer .imgVertical {
            height: 100%;
            width: auto;
        }

        .item .imageContainer .noPhoto {
            width: 72px;
            height: 72px;
            color: var(--palette-2-4);
        }
    
    .item .sectionEditButtons {
        display: flex;
        flex-direction: column;
        border-left: 1px solid var(--palette-2-6);
    }

        .item .sectionEditButtons>* {
            height: 50%;
            width: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--palette-6-5);
            opacity: 0.5;
        }
        .item .sectionEditButtons>*:hover {
            cursor: pointer;
            opacity: 1;
            background: var(--palette-6-7);
        }

        .item .sectionEditButtons .edit {
            color: var(--palette-1-5)!important;
            border-top: 1px solid var(--palette-2-6);
        }
        .item .sectionEditButtons .edit:hover {
            background: var(--palette-1-7)!important;
        }
    


