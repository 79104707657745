@import url("../../../Palette.css");

.admin-courses-empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
}
.admin-courses-list {
    position: relative;
    width: 80%;
    animation: courses-fadein 0.6s;
}

.admin-courses-scrollable {
    flex-grow: 1;
    overflow-y: auto;
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    padding: 0 24px;
    margin: 24px 0;
    animation: scrollview-fadein 0.6s ease-out;
}

@keyframes scrollview-fadein {
    from {
        margin-top: 48px;
        opacity: 0;
    }
    to {
        margin-top: 24px;
        opacity: 1;
    }    
}

.courses-page{
    /* padding: 0 48px; */
}

.courses-title {
    padding: 16px 0;
    display: flex;
    align-items: center;
    color: var(--palette-1-1);
}
.courses-title svg{
    height: 32px;
    width: 32px;
    margin: 10px;
}

.courses-flexbox {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
}
@keyframes courses-fadein {
    from {
        opacity: 0;
        top: 36px;
    }
    to {
        opacity: 1;
        top: 0px;
    }
}

.course-item {
    position: relative;
    width: fit-content;
    max-width: 100%;
    height: 96px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 16px 8px;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 60%);
    border-radius: 12px;
    background: linear-gradient(#ffffff, var(--palette-2-7));
    animation: courseitem-fadein .6s;
    /* padding: 36px; */
}
.course-item:hover {
    cursor: pointer;
    background: linear-gradient(var(--palette-1-7), var(--palette-2-7));
}
@keyframes courseitem-fadein {
    from {
        right: -128px;
    }

    to {
        right: 0px;
    }
}

.course-texts {
    display:flex;
    flex-direction: column;
    padding: 0 24px;    
}

    .course-item img {
        height: 100%;
        place-self: center;
        border-top-left-radius: 12px; 
        border-bottom-left-radius: 12px; 
    }
    .course-item h5, p {
            /* white-space: nowrap; Текст не переносится */
            /* text-overflow: ellipsis; */
    }

    .course-name {
        display: flex;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        height: 50%;
        font-weight: 700;
    }
    .course-description {
        height: 50%;    
        font-weight: 500;
        font-size: 12px;
    }
    .course-description span {
        max-height: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }