.courseContainerScrollable {
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;

    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 1px);
    overflow-y: auto;
}

.adminHeader {
    display: flex;
    align-items: center;
    margin: 0px 48px;

    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;

    border-bottom: solid 1px var(--palette-2-5);
    animation: admin-header-fadein 0.3s;
    height: 48px;
}
    
    .adminHeader .backward {
        display: flex;
        align-items: center;
        color: var(--palette-2-4);
    }

    .adminHeader .backward:hover {
        cursor: pointer;
        opacity: 1;
        color: var(--palette-1-6);
    }

        .adminHeader .backward svg{
            width: 36px;
            height: 36px;
            width: auto;
        }

    .adminHeader .title {
        margin-left: 8px;
        display: flex;
        align-items: center;
        color: var(--palette-1-2);
    }


.courseProfileContainer {
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    margin: 24px 48px;
}

.courseProfileContainer .collapser {
    position: absolute;
    height: 100%;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--palette-1-5);
    border: 1px solid var(--palette-2-6); 
    transition: width 0.3s ease-out;
    background: radial-gradient(transparent, #0000000f);
}
    .courseProfileContainer .extended {
        width: 100%;
        /* box-shadow: 0 0 6px -3px; */
    }
    .courseProfileContainer .collapserContainer {
        position: relative;
        height: 36px;
        display: flex;
        align-items: center;
    }

        .courseProfileContainer .collapserContainer text {
            margin-left: 36px;
            padding-left: 12px;
            overflow: hidden;
            color: var(--palette-1-2);
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-size: 18px;
            font-weight: 700;
            text-decoration: underline;
            opacity: 1;
            /* animation: collapserText-fadein 0.3s ease-in forwards; */
            /* animation-delay: 0.1s; */
        }
            
        @keyframes collapserText-fadein {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

    .courseProfileContainer .collapser:hover {
        cursor: pointer;
        background: var(--palette-1-7);
    }

        .courseProfileContainer .collapser svg {
            pointer-events: auto;
        }

        .courseProfileContainer .collapser svg:hover {
        }

.courseProfile {
    border: solid 1px var(--palette-2-6);
    background: linear-gradient(1deg, #d898ff0a, transparent, #d898ff0a);
    border-top: none;
    animation: courseProfile-fadein .9s;
    overflow: hidden;
    /* box-shadow: 0 0 6px -3px; */
    /* z-index: 20; */
    position: relative;
} 
    .courseProfile.exit {
        animation: courseProfile-fadeout 1.2s;
    }

@keyframes courseProfile-fadein {
    from {
        transform: scale(0.6) rotate(20deg);
        opacity: 0;
    }
    to {
        transform: scale(1) rotate(0deg);
        opacity: 1;
    }
}
@keyframes courseProfile-fadeout {
    from {
        transform: scale(1) rotate(0deg);
        opacity: 1;
    }
    to {
        transform: scale(0) rotate(90deg);
        opacity: 0;
    }
}

    .courseProfile .mainInfo {
        display: flex;
    }

        .courseProfile .mainInfo .imageContainer {
            width: 256px;
            position: relative;
            display: flex;
        }

            .courseProfile .mainInfo .imageContainer img {
                display: block;
                height: fit-content;
                width: 100%;
            }

            .courseProfile .mainInfo .imageContainer .courseNoPhoto {
                width: 100%;
                height: 100%;
                color: var(--palette-2-4);
            }
    
        .courseProfile .mainInfo .text {
            flex: 1;
            display: flex;
            flex-direction: column;
            height: auto;
            padding: 0 24px;
        }
            .courseProfile .mainInfo .text .header {
                border-bottom: solid 1px var(--palette-2-6);
                font-weight: 600;
                font-size: 24px;
                color: var(--palette-1-2);
                display: flex;
            }

                .courseProfile .mainInfo .text .header .name {
                    padding: 4px 0;
                    font-weight: 600;
                    font-size: 24px;
                    flex: 1;
                    color: var(--palette-1-2);
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .courseProfile .mainInfo .text .header .name textarea {
                    width: 100%;
                    resize: none;
                }

                .courseProfile .mainInfo .text .description {
                    flex: 1;
                    position: relative;
                    overflow-y: auto;
                    margin: 12px 0;
                    font-weight: 400;
                }
                
                    .courseProfile .mainInfo .text .description text {
                        overflow-wrap: break-word;
                        position: absolute;
                        max-height: 100%;
                        width: 100%;
                        height: 100%;   
                        font-size: 12px;
                    }
                
                        .courseProfile .mainInfo .text .description textarea {
                            width: 100%;
                            height: 99%;
                            resize: none;
                        }

                .courseProfileContainer .collapserContainer .editButtons {
                    animation: collapserText-fadein 0.3s ease-in forwards;
                    opacity: 0;
                }

                .courseProfileContainer .collapserContainer .editButtons,
                .courseProfile .mainInfo .text .header .editButtons {
                    display: flex;
                    margin-left: auto;
                    align-items: center;
                }

                    .courseProfileContainer .collapserContainer .editButtons svg,
                    .courseProfile .mainInfo .text .header .editButtons svg {
                        width: 24px;
                        height: 24px;
                        color: var(--palette-1-5);
                        margin-left: 4px;
                        border-radius: 6px;
                    }

                        .courseProfileContainer .collapserContainer .editButtons svg.redButton,
                        .courseProfile .mainInfo .text .header .editButtons svg.redButton {
                            color: red;
                        }
                    
                    .courseProfileContainer .collapserContainer .editButtons  svg:hover,
                    .courseProfile .mainInfo .text .header .editButtons svg:hover {
                        cursor: pointer;
                        color: var(--palette-3-4);
                        background: var(--palette-2-6);
                    }                

    .courseProfile .extraInfo {
        width: 100%;
        padding: 12px 0;
        table-layout: fixed;
        border-top: solid 1px #dddddd;
        /* background: var(--palette-2-7); */
        /* border-top: solid 1px var(--palette-2-7); */
        /* background: linear-gradient(0deg, var(--palette-2-7), transparent); */
    }

        .courseProfile .extraInfo td {
            text-align: center;
        }

        .courseProfile .extraInfo .keys {
            font-weight: 700;
            font-size: 14px;
            color: var(--palette-1-3);
            height: 24px;
        }

        .courseProfile .extraInfo .values {
            min-height: 24px;
            height: 24px;
            font-size: 12px;
            font-weight: 400;
        }

            .courseProfile .extraInfo .values .creator {
                display: flex;
                justify-content: center;
            }
            

                .courseProfile .extraInfo .values .creator .photo {

                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    border: solid 0px var(--palette-2-3);
                    /* box-shadow: 0px 0px 6px -1px #000000; */
                }

                .courseProfile .extraInfo .values .creator .nullPhoto {

                    width: 24px;
                    height: 24px;
                    color: var(--palette-2-2);
                    opacity: .5;
                }
                

                .courseProfile .extraInfo .values .creator .name {
                    color: var(--palette-2-2);
                    display: flex;
                    align-items: center;
                }
                
                    .courseProfile .extraInfo .values .creator .name text {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        text-align: left;
                        margin-left: 4px;
                    }