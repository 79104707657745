@import url("../../../Palette.css");
   
.profile-page {
    width: 100%;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile-header { 
    border-bottom: solid 2px var(--palette-2-6);
    width: 95%;
    height: 72px;
    /* padding: 0 32px; */
    display: flex;
    color: var(--palette-1-2);
    align-items: center;
}
    .profile-header svg { 
        width: 32px;
        height: 32px;
    }
    .profile-header h1 {
        margin-left: 8px;
    }

    
.profile-table {
    height: fit-content;
    width: 100%;
    padding: 32px;
    max-width: 920px;
    animation: profile-table-fadein 0.6s;
}

@keyframes profile-table-fadein {
    from {
        margin-top: 36px; opacity: 0; }
    to {
        margin-top: 0px; opacity: 1; }
}
    .profile-table tr {
        height: 48px;
        background: linear-gradient(transparent, var(--palette-2-7));
    }

    .profile-table td {
        padding: 0 8px;
        text-align: end;
    }

    .profile-table .upload-icon {
        margin: 0 8px;
        color: var(--palette-1-4);
        opacity: .5;
        width: 16px;
        height: 16px;
    }
    .profile-table .upload-icon:hover {
        cursor: pointer;
        opacity: 1;
    }

    

    .profile-table .trash-icon {
        margin: 0 8px;
        color: var(--palette-6-4);
        opacity: .5;
        width: 16px;
        height: 16px;
    }
    .profile-table .trash-icon:hover {
        cursor: pointer;
        opacity: 1;
    }
    .profile-table .trash-icon-inactive {
        margin-left: 8px;
        color: var(--palette-2-4);
        opacity: .5;
        width: 16px;
        height: 16px;
    }
    .profile-table .user-img-container {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
    }

    .profile-table input {
        text-align: center;
    }
    
    .profile-table .profile-value,
    .profile-table .edit-icon {
        text-align: center;
    }
        .profile-table .edit-icon-edit {
            display: flex;
            justify-content: space-around;
        }
        .profile-table .edit-icon svg {

            color: var(--palette-1-5);
            opacity: 0.5;
            right: 0;
            width: 16px;
            height: 16px;
        }
        .profile-table .edit-icon svg:hover {
            cursor: pointer;
            opacity: 1;
        }
        .profile-table .edit-icon .locked {
            background-color: var(--palette-2-7);
            pointer-events: none;
    }
    .profile-table .check-mark {
        color: var(--palette-4-4)!important;
        margin: 4px;
    }
    .profile-table .cross-mark {
        color: var(--palette-6-4)!important;
        margin: 4px;
    }

    
    .profile-table input {
        border: none;
        outline: none;
        background: transparent;
        border-bottom: 1px solid #2368a247;
    }

    .profile-table h2{    
        text-align: end;
        justify-content: flex-end;
    }
    .profile-table h2, h3 {
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
    }
    
    .profile-table .profile-fieldname {
        width: 164px;
    }
    
    .profile-table .edit-icon {
        width: 82px;
    }