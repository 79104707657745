.sectionPage {
    max-width: 100%;
    width: 100%;
    padding: 24px;
}

.sectionProfileContainer {
    border: 1px solid var(--palette-1-7);
    background: #f8f9fa38;
    box-shadow: 0 0 3px -2px;
    margin: 24px;
}

    .sectionProfileContainer .title {
        display: flex;
    }

        .sectionProfileContainer .title .backward {
            min-width: 36px;
            min-height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--palette-2-2);
            border-radius: 2px;
            /* border-bottom: 1px solid var(--palette-1-7);
            border-right: 1px solid var(--palette-1-7); */
            transition: all 0.15s;
        }
        
        .sectionProfileContainer .title .backward:hover {
            box-shadow: 0 0 5px;
        }

            .sectionProfileContainer .title .backward svg {
                width: 28px;
                height: 28px;
            }

        .sectionProfileContainer .title .name {
            white-space: nowrap;
            overflow: hidden;

            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            text-decoration: underline;
            color: var(--palette-2-2);
            padding: 0 8px;
        }

            .sectionProfileContainer .title .name span {
                text-overflow: ellipsis;
                overflow: hidden;
            }

        .sectionProfileContainer .title .buttons {
            display: flex;
            margin-left: auto;
        }

            .sectionProfileContainer .title .buttons .button {
                display: flex;
                height: 100%;
                width: 36px;
                align-items: center;
                justify-content: center;
                border-radius: 2px;
                transition: all 0.15s;
            }

            .sectionProfileContainer .title .buttons .button:hover {
                box-shadow: 0 0 5px;
                cursor: pointer;
            }

                .sectionProfileContainer .title .buttons .button.edit {
                    color: var(--palette-1-3);
                }
                .sectionProfileContainer .title .buttons .button.ok {
                    color: var(--palette-4-4);
                }
                .sectionProfileContainer .title .buttons .button.cancel {
                    color: var(--palette-2-2);
                }

                .sectionProfileContainer .title .buttons .button svg {
                    height: 20px;
                    width: 20px;
                }

    .sectionProfileContainer .sectionProfile {
        padding: 24px;
    }

        .sectionProfileContainer .sectionProfile .name {
            font-size: 16px;
            font-weight: 600;
            color: var(--ansuya-palette-4);
        }

        .sectionProfileContainer .sectionProfile .name textarea {
            resize: none;
            width: 100%;
            background: transparent;
            border-radius: 4px;
            border: 1px solid var(--palette-2-5);
            padding: 8px;
            font-size: 14px;
        }

        .sectionProfileContainer .sectionProfile .descriptionContainer {
            display: flex;
            margin-top: 12px;
        }

            .sectionProfileContainer .sectionProfile .descriptionContainer .imageContainer {
                min-width: 256px;
                width: 256px;
                height: 100%;
                position: relative;
                display: flex;
            }
                
                .sectionProfileContainer .sectionProfile .descriptionContainer .imageContainer img {
                    display: block;
                    width: 100%;
                }

                .sectionProfileContainer .sectionProfile .descriptionContainer .imageContainer .sectionNoPhoto {
                    width: 100%;
                    height: auto;
                    color: var(--palette-2-4);
                }

                .sectionProfileContainer .sectionProfile .descriptionContainer .description {
                    flex: 1 1;
                    position: relative;
                    overflow-y: auto;
                    margin: 12px 0;
                    font-weight: 400;
                }

                    .sectionProfileContainer .sectionProfile .descriptionContainer .description textarea {
                        resize: none;
                        width: 100%;
                        height: 98%;
                        background: transparent;
                        border-radius: 4px;
                        border: 1px solid var(--palette-2-5);
                        padding: 8px;
                        font-size: 14px;
                    }

                .sectionProfileContainer .sectionProfile .descriptionContainer .description text {
                    overflow-wrap: break-word;
                    position: absolute;
                    max-height: 100%;
                    width: 100%;
                    height: 100%;
                    font-size: 14px;
                    padding: 14px;
                }


.lessons {
    padding: 0 24px;
}

    .lessons .lessonsListHeader {
        height: 36px;
        display: flex;
        align-items: center;
        border: 1px solid var(--palette-2-7);
        box-shadow: 0 0 3px -2px;
        /* background: linear-gradient(180deg, var(--palette-2-7), transparent); */
    }

        .lessons .lessonsListHeader h4 {
            margin-left: 8px;
            color: #3f3222b3;
            font-family: inherit;
            font-size: 14px;
            font-weight: 700;
        }

        .lessons .lessonsListHeader .newLessonButton {
            display: flex;
            width: 36px;
            height: 100%;
            align-items: center;
            justify-content: center;
            color: var(--palette-3-3);
            transition: all 0.15s;
            border-right: 1px solid var(--palette-2-7);
            border-radius: 2px;
        }

        .lessons .lessonsListHeader .newLessonButton:hover {
            box-shadow: 0 0 3px 0px inset;
        }

            .lessons .lessonsListHeader .newLessonButton svg {
                width: 18px;
                height: 18px;
            }

    .lessons .lessonsList {
        padding: 0px;
    }

        .lessons .lessonsList .noLessons {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 64px;
            color: var(--ansuya-palette-2);
            font-weight: 400;
        }

            .lessons .lessonsList .noLessons span {

            }

        .lessons .lessonsList .lessonItem {
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--palette-2-6);
            height: 48px;
            margin: 0 20px;
        }

            .lessons .lessonsList .lessonItem .minusButton {
                height: 100%;
                width: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--palette-6-4);
                transition: all 0.15s;
            }

            .lessons .lessonsList .lessonItem .minusButton:hover {
                cursor: pointer;
                box-shadow: 0 0 5px inset;
                
            }

                .lessons .lessonsList .lessonItem .minusButton svg {

                }

            .lessons .lessonsList .lessonItem .lessonName {
                font-size: 14px;
                font-weight: 500;
                color: var(--ansuya-palette-4);
                margin-left: 8px;
            }
@media (max-width: 640px) {
    .sectionPage {
        padding: 0;
    }

    .sectionProfileContainer .sectionProfile {
        padding: 12px;
    }

    .sectionProfileContainer .sectionProfile .name {
        font-size: 14px;
    }

    .sectionProfileContainer .sectionProfile .descriptionContainer {
        flex-direction: column;
        align-items: center;
    }

    .sectionProfileContainer .sectionProfile .descriptionContainer .imageContainer {
        min-width: 100%;
        width: 100%;
    }

    .sectionProfileContainer .sectionProfile .descriptionContainer .description {
        width: 100%;
        max-height: 192px;
    }

    .sectionProfileContainer .sectionProfile .descriptionContainer .description text {
        position: static;
        font-size: 12px;
    }
}
