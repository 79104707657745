.mainContent {
    display: flex;    
    position: relative;
    margin-top: var(--header-height);
    overflow: auto;
    /* background: white; */
    /* height: fit-content; */
    width: 100%;
    /* max-height: calc(100vh - 1px); */
    /* height: calc(100vh - 1px - var(--header-height)); */
    /* min-height: 640px; */
    /* box-shadow: 0px 0px 20px -10px black; */
    
    /* margin-top: 64px; */
}

    .mainContent .contentWithFooter {

    }

@media (max-width: 1420px) {
    
    .mainContent {
        /* width: calc(100% - 96px); */
    }
}

@media (max-width: 906px) {
    .mainContent {
        /* max-width: 810px;
        width: 100%; */
        /* max-width: 810px; */
    }
}