
:root {
  --palette-1-1: #203d54;
  --palette-1-2: #1a4971;
  --palette-1-3: #2368a2;
  --palette-1-4: #3183c8;
  --palette-1-5: #63a2d8;
  --palette-1-6: #aad4f5;
  --palette-1-7: #eff8ff;


  --palette-2-1: #212934;
  --palette-2-2: #5f6b7a;
  --palette-2-3: #8895a7;
  --palette-2-4: #b8c4ce;
  --palette-2-5: #b8c4ce;
  --palette-2-6: #e1e7ec;
  --palette-2-7: #f8f9fa;


  --palette-3-1: #124544;
  --palette-3-2: #1b655e;
  --palette-3-3: #2a9187;
  --palette-3-4: #3caea3;
  --palette-3-5: #6ed7d3;
  --palette-3-6: #a8eeeb;
  --palette-3-7: #e7fffe;


  --palette-4-1: #145239;
  --palette-4-2: #197741;
  --palette-4-3: #259d58;
  --palette-4-4: #38c172;
  --palette-4-5: #74d99f;
  --palette-4-6: #a8eec1;
  --palette-4-7: #e3fcec;


  --palette-5-1: #5c4813;
  --palette-5-2: #8c6d1f;
  --palette-5-3: #caa53d;
  --palette-5-4: #f4ca64;
  --palette-5-5: #fae29f;
  --palette-5-6: #fdf3d7;
  --palette-5-7: #fffcf4;


  --palette-6-1: #611818;
  --palette-6-2: #891b1b;
  --palette-6-3: #b82020;
  --palette-6-4: #dc3030;
  --palette-6-5: #e46464;
  --palette-6-6: #f5aaaa;
  --palette-6-7: #fce8e8;
}




html {
    height: 100%;
  }
  /* body {
    margin:0;
    padding:0;    
    font-family: 'Montserrat', sans-serif;
    background: linear-gradient(#ffffff, #e7e7e7);
  } */
  
  .login-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    padding: 40px;
    transform: translate(-50%, calc(-50% - var(--header-height) / 2));
    background: rgba(0,0,0,.5);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
    border-radius: 10px;
    -webkit-animation: login-box-fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: login-box-fadein 1s; /* Firefox < 16 */
    -ms-animation: login-box-fadein 1s; /* Internet Explorer */
    -o-animation: login-box-fadein 1s; /* Opera < 12.1 */
    animation: login-box-fadein 1s;
    z-index: 999999;
  }
  
  .login-box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: #fff;
    text-align: center;
  }
  
  .login-box .user-box {
    position: relative;
  }
  
  .login-box .user-box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
  }
  .login-box .user-box label {
    position: absolute;
    top:0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: .5s;
  }
  
  .login-box .user-box input:focus ~ label,
  .login-box .user-box input:valid ~ label {
    top: -20px;
    left: 0;
    color: #fff;
    font-size: 12px;
  }
  
  /* .login-box form button {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    background-color: #6a6a6a;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 4px;
    border-radius: 6px;
    border-width: 0px;
    box-shadow: inset 0px -12px 20px 0px rgb(0 0 0 / 11%);
  } */
  
  .login-box .enter-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }

  .login-box form button {
    position: relative;
    /* display: inline-block; */
    padding: 10px 20px;
    color: #fff;
    background-color: #6a6a6a;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    letter-spacing: 4px;
    border-radius: 6px;
    border-width: 0px;
    box-shadow: inset 0px -12px 20px 0px rgb(0 0 0 / 11%);
  }

  .login-box button:hover {
    cursor: pointer;
    color: #fff;
    background-color: #9f9f9f;
  }
  
  .login-box button span {
    position: absolute;
    display: block;
  }


  .content-with-footer {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


@keyframes login-box-fadein {
  from {
      opacity: 0;
      top: 45%;
  }

  to {
      opacity: 1;
      top: 50%
  }
}

/* Firefox < 16 */
@-moz-keyframes login-box-fadein {
  from {
      opacity: 0;
      top: 45%;
  }

  to {
      opacity: 1;
      top: 50%
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes login-box-fadein {
  from {
      opacity: 0;
      top: 45%;
  }

  to {
      opacity: 1;
      top: 50%
  }
}

/* Internet Explorer */
@-ms-keyframes login-box-fadein {
  from {
      opacity: 0;
      top: 45%;
  }

  to {
      opacity: 1;
      top: 50%
  }
}