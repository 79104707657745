.item {
    margin-bottom: 16px;
    background: linear-gradient(0deg, var(--palette-2-7), transparent);
    position: relative;
    animation: course-list-fadein 0.6s;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    border: 1px solid var(--palette-2-6);
    box-shadow: 0 0 6px -3px var(--palette-2-3);
    /* padding: 12px; */
}

@keyframes course-list-fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}
.courseClickable {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex: 1;
    text-decoration: none;
    color: black;
}
    .courseClickable:hover {
        background-color: var(--palette-2-6);
        cursor: pointer;
    }

.textContainer {
    /* margin: 0 12px; */
    font-size: 14px;
    flex-grow: 1;
    position: relative;
}

    .textContainer .courseName {
        border-bottom: solid 1px #e1e7ec80;
        padding: 0 12px;
        display: flex;
        height: 50%;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
    }

        .textContainer .courseName .name {
            flex-grow: 1;
            width: 150px;
            text-overflow: ellipsis;
            overflow: hidden; 
            white-space: nowrap;
        }
        .textContainer .description {
            display: flex;
            align-items: center;
            height: 50%;
            padding: 0 12px;
            font-size: 10px;
            flex-wrap: wrap;
        }

        .description div {
            flex-grow: 1;
            width: 150px;
            min-height: 26px;
            max-height: 26px;
            text-overflow: ellipsis;
            overflow: hidden; 

            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: fit-content;
        }

    .otherInfoContainer {
        /* border-left: 1px solid #e1e7ec80; */
    }
        
        .otherInfoContainer .created,
        .otherInfoContainer .creator {
            height: 50%;
            padding: 0 12px;
            display: flex;
            align-items: center;
        }

    .otherInfoContainer .created {
        border-bottom: 1px solid #e1e7ec80;
        color: var(--palette-1-4);
    }

.buttons {
    display: flex;
    flex-direction: column;    
    border-left: 1px solid var(--palette-2-6);

}

    .buttons .editIcon, 
    .buttons .deleteIcon  { 
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 50%;
        opacity: 0.5;
        color: var(--palette-1-5);
    }

    .buttons .deleteIcon { 
        color: var(--palette-6-5)!important;
        border-bottom: 1px solid var(--palette-2-6);
    }
    
    .buttons .goTo {
        display: flex;
        flex: 1;
        width: 36px;
        opacity: 0.5;
        align-items: center;
        justify-content: center;
        
        color: var(--palette-4-3);
        background-color: aqua;
        border-left: 1px solid white;
    }

        .buttons  .goTo>* {
            height: 100%;
            
        }
    
    .buttons .editIcon:hover, 
    .buttons .deleteIcon:hover {
        background: var(--palette-2-6);
        cursor: pointer;
        opacity: 1;
    }
    
    .corseImageContainer {
        width: 72px;
        height: 72px;
        /* background: #00000029; */
        display: flex;
        border-right: solid 1px #e1e7ec80;
    }
        .corseImageContainer img {
            display: block;
            margin: auto;
            margin-top: auto;
            text-align: center;
        }

        .corseImageContainer .imgHorisontal {
            width: 100%;
            height: auto;
        }

        .corseImageContainer .imgVertical {
            height: 100%;
            width: auto;
        }

        .corseImageContainer .courseNoPhoto {
            width: 72px;
            height: 72px;
            color: var(--palette-2-4);
        }
        
.courseItemCollapser { 
    width: 100%;
    top: 44px;
    height: 12px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--palette-2-4);
    opacity: 0.8;
}
    .courseItemCollapser:hover {
        background: linear-gradient(transparent, var(--palette-2-7));
        cursor: pointer;
    }
    