.coursePage {
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;

    display: flex;
    flex-direction: column;
    align-items: center;
    /* max-height: calc(100vh - 1px); */
    /* overflow-y: auto; */
}

    .header {
        display: flex;
        align-items: center;
        margin: 0px 46px;
        margin-top: 24px;

        width: 100%;
        width: -moz-available;          /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
        width: fill-available;

        /* border-bottom: solid 1px var(--palette-2-5); */
        min-height: 48px;
    }
        
        .header .backward {
            display: flex;
            align-items: center;
            color: var(--palette-2-4);
        }

        .header .backward:hover {
            cursor: pointer;
            opacity: 1;
            color: var(--palette-1-6);
        }

            .header .backward svg{
                width: 36px;
                height: 36px;
                width: auto;
            }

        .header .title {
            margin-left: 13px;
            display: flex;
            align-items: center;
            color: var(--palette-1-2);
        }

    .courseProfileContainer {
        width: 100%;
        width: -moz-available;          /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
        width: fill-available;
        margin: 24px 48px;
    }

    .courseProfileContainer .collapser {
        position: absolute;
        height: 100%;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--palette-1-5);
        border: 1px solid var(--palette-2-6); 
        transition: width 0.3s ease-out;
        background: radial-gradient(transparent, #0000000f);
    }
        .courseProfileContainer .extended {
            width: 100%;
            background: linear-gradient(180deg, #00000003, transparent);
            border-bottom: 0;
            /* box-shadow: 0 0 6px -3px; */
        }
        .courseProfileContainer .collapserContainer {
            position: relative;
            height: 36px;
            display: flex;
            align-items: center;
        }

            .courseProfileContainer .collapserContainer text {
                margin-left: 36px;
                padding-left: 12px;
                overflow: hidden;
                color: var(--palette-1-2);
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                font-size: 18px;
                font-weight: 700;
                text-decoration: underline;
                opacity: 1;
                /* animation: collapserText-fadein 0.3s ease-in forwards; */
                /* animation-delay: 0.1s; */
            }
                
            @keyframes collapserText-fadein {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }

        .courseProfileContainer .collapser:hover {
            cursor: pointer;
            background: var(--palette-1-7);
        }

            .courseProfileContainer .collapser svg {
                pointer-events: auto;
            }

            .courseProfileContainer .collapser svg:hover {

            }

    .courseProfile {
        border: solid 1px var(--palette-2-6);
        /* background: linear-gradient(1deg, #d898ff0a, transparent, #d898ff0a); */
        border-top: none;
        animation: courseProfile-fadein .9s;
        overflow: hidden;
        /* box-shadow: 0 0 6px -3px; */
        /* z-index: 20; */
        position: relative;
    } 
        .courseProfile.exit {
            animation: courseProfile-fadeout 0.6s;
        }

    @keyframes courseProfile-fadein {
        from {
            transform: scale(0.85);
            opacity: 0;
        }
        to {
            transform: scale(1);
            opacity: 1;
        }
    }
    @keyframes courseProfile-fadeout {
        from {
            transform: scale(1) rotate(0deg);
            opacity: 1;
        }
        to {
            transform: scale(0) rotate(90deg);
            opacity: 0;
        }
    }

        .courseProfile .mainInfo {
            display: flex;
        }

            .courseProfile .mainInfo .imageContainer {
                width: 256px;
                position: relative;
                display: flex;
            }

                .courseProfile .mainInfo .imageContainer img {
                    display: block;
                    height: 100%;
                    width: 100%;
                }

                .courseProfile .mainInfo .imageContainer .courseNoPhoto {
                    width: 100%;
                    height: 100%;
                    color: var(--palette-2-4);
                }
        
            .courseProfile .mainInfo .text {
                flex: 1;
                display: flex;
                flex-direction: column;
                height: auto;
                padding: 0 24px;
            }
                .courseProfile .mainInfo .text .title {
                    border-bottom: solid 1px var(--palette-2-6);
                    font-weight: 600;
                    font-size: 24px;
                    color: var(--palette-1-2);
                    display: flex;
                }

                    .courseProfile .mainInfo .text .title .name {
                        padding: 4px 0;
                        font-weight: 600;
                        font-size: 24px;
                        flex: 1;
                        color: var(--palette-1-2);
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }

                    .courseProfile .mainInfo .text .description {
                        flex: 1;
                        position: relative;
                        overflow-y: auto;
                        margin: 12px 0;
                        font-weight: 400;
                    }
                    
                        .courseProfile .mainInfo .text .description text {
                            overflow-wrap: break-word;
                            position: absolute;
                            max-height: 100%;
                            width: 100%;
                            height: 100%;   
                            font-size: 12px;
                        }      

        .courseProfile .extraInfo {
            width: 100%;
            padding: 12px 0;
            table-layout: fixed;
            border-top: solid 1px #dddddd;
            /* background: var(--palette-2-7); */
            /* border-top: solid 1px var(--palette-2-7); */
            /* background: linear-gradient(0deg, var(--palette-2-7), transparent); */
        }

            .courseProfile .extraInfo td {
                text-align: center;
            }

            .courseProfile .extraInfo .keys {
                font-weight: 700;
                font-size: 14px;
                color: var(--palette-1-3);
                height: 24px;
            }

            .courseProfile .extraInfo .values {
                min-height: 24px;
                height: 24px;
                font-size: 12px;
                font-weight: 400;
            }

                .courseProfile .extraInfo .values .creator {
                    display: flex;
                    justify-content: center;
                }
                

                    .courseProfile .extraInfo .values .creator .photo {

                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        border: solid 0px var(--palette-2-3);
                        /* box-shadow: 0px 0px 6px -1px #000000; */
                    }

                    .courseProfile .extraInfo .values .creator .nullPhoto {

                        width: 24px;
                        height: 24px;
                        color: var(--palette-2-2);
                        opacity: .5;
                    }
                    

                    .courseProfile .extraInfo .values .creator .name {
                        color: var(--palette-2-2);
                        display: flex;
                        align-items: center;
                    }
                    
                        .courseProfile .extraInfo .values .creator .name text {
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            text-align: left;
                            margin-left: 4px;
                        }
                        
    .sectionsContainer {
        width: 100%;
        width: -moz-available;          /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
        width: fill-available;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        /* overflow-y: hidden; */
        /* max-height: calc(100vh - 1px); */
        transition: max-height 0.3s ease-in-out;
        padding: 0 48px;
    }

    .collapsed {
        /* max-height: 0!important; */
    }
        .sectionsList {
            opacity: 1;
            /* transition: opacity 0.4s ease-in-out; */
        }

        .collapsed .sectionsList {
            /* opacity: 0!important; */
        }

        .sectionsContainer .title {
            display: flex;
            align-items: center;
            width: 100%;
            width: -moz-available;          /* WebKit-based browsers will ignore this. */
            width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
            width: fill-available;
            /* border: solid 1px var(--palette-2-6); */
            min-height: 36px;
            animation: header-fadein 0.3s ease-out forwards;
            opacity: 0;
        }

        @keyframes header-fadein {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

        .sectionsContainer .collapser {
            height: 100%;
            width: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--palette-1-5);
            border: 1px solid var(--palette-2-6); 
            background: radial-gradient(transparent, #0000000f);
        }

        .sectionsContainer .collapser:hover {
            cursor: pointer;
            background: var(--palette-1-7);
        }

            .sectionsContainer .collapser svg {
                pointer-events: auto;
            }

            .sectionsContainer .collapser svg:hover {
                
            }

        .sectionsContainer .title .headerTitle {
            display: flex;
            align-items: center;
            margin-left: 12px;
            color: var(--palette-1-2);
            width: auto;
        }
        
            .sectionsContainer .title .clickIcons {
                padding: 0 12px;
                display: flex;
            }

            .sectionsContainer .title .clickIcons >* {
                width: 20px;
                height: 20px;
                margin: 4px 2px;
                color: var(--palette-2-4);
                opacity: 50%;
            }
            
            .sectionsContainer .title .clickIcons >*:hover, 
            .sectionsContainer .title .clickIcons >*.active {
                color: var(--palette-1-6);
                opacity: 1;
                cursor: pointer;
            }

    .sectionsList {
        /* overflow-y: scroll; */
        width: 100%;
        width: -moz-available;          /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
        width: fill-available;
        margin: 24px 0;
        margin-bottom: 0;
        /* padding: 0 24px; */
        /* border: 1px solid #8186bd14; */
    }
    
        .sectionsList .showMore {    
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 16px;
            font-size: 12px;
            border-radius: 8px;
            box-shadow: 0 0 6px -3px var(--palette-1-2);
        }
            .sectionsList .showMore:hover {
                background: radial-gradient(#ffffff, #d1e4ff5c);
                cursor: pointer;
            }
            .sectionsList .showMore svg {    
                height: 100%;
                width: 24px;
                color: #6495edc7;
            }
    
        .sectionsList .item {
            margin-bottom: 16px;
            /* background: linear-gradient(0deg, var(--palette-2-7), #ffffff); */
            animation: sections-list-fadein 0.6s;
            display: flex;
            flex-wrap: wrap;    
            align-items: stretch;
            /* border: solid 1px #ededed; */
            transition: all .15s;
            border-radius: 2px;
        }
        .sectionsList .item:hover {
            box-shadow: 0 0 5px -2px;
        }

            .sectionsList .item .textContainer {
                flex: 1;
                display: flex;
                flex-direction: column;
                margin-left: 12px;
            }
        
                .sectionsList .item .textContainer text {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
                .sectionsList .item .textContainer .description text {
                    -webkit-line-clamp: 2;
                }
    
                .sectionsList .item .textContainer .name {
                    font-weight: 700;
                    color: #462a91;
                    height: 50%;
                    display: flex;
                    align-items: center;
                }
    
                .sectionsList .item .textContainer .description {
                    font-size: 12px;
                    height: 50%;
                    font-weight: 400;
                    color: var(--palette-1-2);
                }
    
        @keyframes sections-list-fadein {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    
        .item .imageContainer {
            width: 72px;
            height: 72px;
            display: flex;
            margin-left: 8px;
        }
    
            .item .imageContainer img {
                display: block;
                margin: auto;
                margin-top: auto;
                text-align: center;
            }
    
            .item .imageContainer .imgHorisontal {
                width: 100%;
                height: auto;
            }
    
            .item .imageContainer .imgVertical {
                height: 100%;
                width: auto;
            }
    
            .item .imageContainer .noPhoto {
                width: 72px;
                height: 72px;
                color: var(--palette-2-4);
            }

@media (max-width: 640px) {
    .courseProfile .mainInfo .imageContainer {
        max-width: 128px;
        width: 128px;
    }

    .courseProfileContainer {
        margin: 24px;
        margin-top: 0px;
    }

    .courseProfile .mainInfo .text {
        padding: 0 12px;
    }

    .courseProfile .mainInfo .text .title .name {
        font-size: 15px;
    }
                    
    .courseProfile .mainInfo .text .description text {
        font-size: 12px;
    }
                    
    .courseProfile .mainInfo .text .description {
        margin: 6px 0;
    }

    .courseProfile .extraInfo .values .creator {
        flex-direction: column;
        align-items: center;
    }

    .courseProfile .extraInfo .keys {
        font-size: 12px;
    }
                        
    .sectionsContainer {
        padding: 0 24px;
    }

    .header {
        margin-top: 12px;
        margin: 0 16px;
    }

    .coursePage h3 {
        font-size: 16px;
    }
}