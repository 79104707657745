
.roleContainer {
    position: relative;
    justify-content: center;
    display: flex;
}
    
    .roleContainer svg { 
        width: 24px;
        height: 24px;
    }
    
        .roleContainer:hover {
            & text {
                opacity: 1;
                transition-delay: 1s;
            }
        }
    
    .roleContainer:has(.user) { 
        color: var(--palette-1-5);
    }
    
    .roleContainer:has(.admin) {
        color: rgb(103 44 196);
        /* color: rgb(139, 0, 139); */
    }
    
    .roleContainer:has(.director) { 
        color: rgb(255, 69, 0);
    }
    
    .roleContainer text {
        background: linear-gradient(0deg, #f1f1f1, white);
        opacity: 0;
        pointer-events: none;
        position: absolute;
        padding: 4px;
        top: 24px;
        border: 1px solid;
        border-radius: 6px;
        font-size: 12px;
        font-weight: 600;
        transition: all 0.6s;
    }