@import url("../../Palette.css");

.admin-users-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-courses-container {
    width: 100%;
    /* padding: 0 48px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 1px);
    transition: height 0.15s ease-out;
}

.admin-header {
    display: flex;
    align-items: center;
    
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;

    border-bottom: solid 1px var(--palette-2-5);
    animation: admin-header-fadein 0.3s;
    min-height: 64px;
}

@keyframes admin-header-fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.admin-header input {
    border: none;
    outline: none;
    border-bottom: 1px solid var(--palette-1-3);
    opacity: 0.25;
    width: 128px;
    animation: input-fadein 0.1s ease-out;
}

    @keyframes input-fadein {
        from { width: 0; }
        to { width: 128px; }
    }

.admin-header .header-sorting-container {
    /* margin-left: auto; */
    padding: 0 16px;
    display: flex;
}

.admin-header .header-sorting-container svg:hover, .admin-header .header-sorting-container svg.active {
    color: var(--palette-1-6);
    opacity: 1;
    cursor: pointer;
}
.admin-header .header-sorting-container input:hover {
    color: var(--palette-1-3);
    opacity: 1;
    cursor: pointer;
}

.admin-header .sorter {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* margin-left: auto; */
    position: relative;
    margin: 0 8px;
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
}

    .sorter .text {
        display: flex;
        align-items: center;
        opacity: 0.5;
        color: var(--palette-1-1);
    }
    .sorter:hover .text {
        cursor: pointer;
        opacity: 1;
    }
    
    .sorter:hover .dropdown {
        display: flex;
        align-items: center;
        flex-direction: column;
        opacity: 1;
        animation: dropdown-fadein 0.2s;
    }
    
        @keyframes dropdown-fadein {
            from { opacity: 0; }
            to { opacity: 1; }
        }
    
        .sorter .text svg {
            color: var(--palette-4-4);
        }

    .sorter .dropdown {
        display: none;
        opacity: 0;
        position: absolute;
        top:14px;
        background: rgb(255 255 255 / 50%);
        min-width: 96px;
        z-index: 100;
        padding: 4px 8px;
        border-radius: 0 0 4px 4px;
        margin-left: -8px;
    }
    .dropdown span {
        margin: 4px 0px;
        opacity: 0.5;
        text-decoration: underline;
    }
    .dropdown span:hover{
        opacity: 1;
        cursor: pointer;
    }

.heder-click-icons {
    padding: 0 16px;
    display: flex;
}
.heder-click-icons >* {
    width: 20px;
    height: 20px;
    color: var(--palette-2-5);
    margin-left: 4px;
    border-radius: 6px;
}

.heder-click-icons >*:hover, .heder-click-icons >*.active {
    color: var(--palette-1-4);
    opacity: 1;
    cursor: pointer;    
    background: #f8f8f8;
}

.admin-header .searcher {
    display: flex;
    margin-left: auto;
} 

.admin-header .searcher::after {
    content: "";
    border-right: 1px solid var(--palette-2-6);
    margin-left: 8px;
    height: calc(100% + 12px);
}

.admin-header .searcher:hover {
    color: var(--palette-1-3);
    opacity: 1;
    cursor: pointer;
}
.heder-title {
    display: flex;
    align-items: center;
    color: var(--palette-1-2);
}
.heder-title > * {
    width: auto;
}

.admin-header .searcher:hover, .admin-header .searcher.active {
    color: var(--palette-1-6);
    opacity: 1;
    cursor: pointer;
}
.admin-header .searcher svg {
    width: 20px;
    height: 20px;
    margin: 4px 2px;
    color: var(--palette-2-4);
    opacity: 50%;
}

.heder-title svg {
    width: 32px;
    height: 32px;
    margin: 0 8px;
}

.user-table {
    border-collapse: separate; 
    border-spacing: 4px;
    width: 100%;
}
.users-list{
    width: 85%;
    padding: 32px 8px;
    animation: list-fadein 0.6s;
}
@keyframes list-fadein {
    from {
        margin-top: 36px; opacity: 0; }
    to {
        margin-top: 0px; opacity: 1; }
}
    .users-list tr {
        /* border-top: solid 2px var(--palette-2-6); */
        /* height: 64px; */
        font-size: 12px;
        background: linear-gradient(white, var(--palette-2-7));
    }

    .users-list td {
        padding: 0 20px;
        /* border-right: solid 2px var(--palette-2-6); */
        /* width: 228px; */
        text-align: center;
    }
    
    .users-list td .edit {
        margin: 8px;
        width: 16px;
        height: 16px;
        color: var(--palette-1-5);
        opacity: 0.5;
    }
    
    
    .users-list td .delete {
        color: var(--palette-6-5);
    }

    .users-list td .edit:hover {
        opacity: 1;
        cursor: pointer;
    }



.name {
    font-size: 14px;
}

.user-row {
    /* margin-top: 15px; */
}
.user-insert-row {
    background: linear-gradient(transparent, var(--palette-5-7))!important

}
.user-row:hover {

}

.null-user-photo {
    width: 48px;
    height: 48px;
    color: var(--palette-2-2);
    opacity: .5;
}

.user-context-menu {
    z-index: 12;
    position: fixed;
    display: flex;
    padding: 4px;
    background:var(--palette-6-7);
    animation: context-fadein .3s;
    
}
@keyframes context-fadein {
    from {
        height: 0px;
    }

    to {
        height: 44px;
    }
}

.user-context-menu svg {
    height: 36px;
    width: 36px;
}
.popup-background {
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 280px;
    animation: popup-fadein 0.6s;
    box-shadow: 0px 0px 10px 0px #000000c0;
    border-radius: 24px;
    background: var(--palette-2-7);
}
@keyframes popup-fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


    .popup .written-delete-confirmation {
        font-size: 14px;
        padding: 8px 0;
    }
    
        .popup .written-delete-confirmation input {
            margin-top: 8px;
            width: 100%;
        }

            .popup .written-delete-confirmation span p {
                font-style: italic;
                font-weight: 500;
                display: inline
            }

        .popup .written-delete-confirmation span p {
            font-style: italic;
            font-weight: 500;
            display: inline
        }

    .popup .buttons {
        display: flex;
        justify-content: space-between;
    }
    .popup button {
        background: linear-gradient(var(--palette-2-7), var(--palette-1-6));
        border: 0;
        width: 112px;
        height: 24px;
        cursor: pointer;
        border-radius: 3px;
        color: var(--palette-1-2);
    }
    .popup .space-around {
        justify-content: space-around!important;
    }
    .popup .cancel {
        background: linear-gradient(var(--palette-2-7), var(--palette-2-6));
    }
    .popup .ok-mark, .popup .cancel-mark {
        width: 24px;
        height: 24px;
        color: var(--palette-6-4);
        opacity: 0.5;
    }
    .popup .ok-mark:hover, .popup .cancel-mark:hover {
        cursor: pointer;
        opacity: 1;
    }
    .popup .cancel-mark {
        color: var(--palette-2-2);    
    }
    .popup input {
        border: none;
        outline: none;
        border-bottom: 1px solid #2368a247;
    }
    .popup form {
        display: flex;
        flex-direction: column;
    }
    .popup form >* {
        margin-top: 16px;
    }

.popup-title {
    display: flex;
    align-items: center;
    color: var(--palette-1-1);
    padding: 4px 0px;
}
.popup-title svg {
    width: 20px;
    height: 20px;
    margin: 0px 4px;
}